import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import classNames from "classnames"

const find = (edges, name) => edges.find(edge => edge.node.name === name)

const sections = [
  {
    images: ["story-1"],
    lines: [
      `«من دوباره حالم اونطوری شده» اینو خیلی آروم پشت تلفن گفتم.`,
      `احساس خفگی تو هوا موج میزد. نمی تونستم باور کنم دوباره درگیر این
      احساس‌ها شدم.
      `,
      `آروم نفس بکش،‌ من خیلی زود میرسم،‌ بابام با آرامش اینا رو گفت.`,
      ` تو دفتر
      مدرسه تنها نشستم. می تونستم احساس دل آشوبه و اشک‌هایی که چشمام رو پر
      کرده بود احساس کنم با خودم گفتم زندگی منصفانه نیست.`,
    ],
  },
  {
    images: ["story-2", "story-3"],
    lines: [
      `
          با سپری شدن روزها، هفته و ماه ها، کم کم یاد گرفتم که اختلال دوقطبی یک
          بیماری روانپزشکیه که شامل دوره های مانیا و افسردگی میشه.
        `,
      `من فهمیدم که خلقم می‌تونه از حالت خیلی سرخوش به غمگینی شدید برسه.`,
    ],
  },
  {
    images: ["story-4"],
    lines: [
      `
          اولش که بیماریم تشخیص داده شد، خیلی سردرگم بودم و احساس ناامنی شدید
          می‌کردم.
        `,
      `
          حتی دیگران و خانواده ام رو با رفتارها و واکنش هام آزار می دادم. اونقدر
          روحیه ام بی ثبات بود که حتی یک روز کامل نمی تونستم تو مدرسه قرار
          بگیرم.
        `,
      `
          اون سال بدترین ۳۶۵ روز زندگیم بود. برام باور کردنی نبود و نمی تونستم
          خودم رو در اون وضعیت جمع و جور کنم.
        `,
      `
          احساس می‌کردم سوار یه ترن هوایی ام که توقف نداره و بالا و پایین رفتن
          هاش هیچ لذتی برام نداشت.
        `,
    ],
  },
  {
    images: ["story-5"],
    lines: [
      `
          من می‌دونستم که نمی‌تونم بیماریم رو عوض کنم و مجبور بودم با دوقطبی دست
          و پنجه نرم کنم، پس رو صورتم یه ماسک گذاشتم.
        `,
      `
          هر روز تو مدرسه وانمود می‌کردم که کاملا خوبم ولی در درون احساس می‌کردم
          کلمه ها دارن بهم چنگ میندازن و فشارها خیلی بیشتر از توانم بود.
        `,
    ],
  },
  {
    images: ["story-6"],
    lines: [
      `
          خوشبختانه، بعد از چند سال الان من یه تیم دارم، که شامل والدینم،
          برادرم، روانپزشک و روانشناسم می‌شه.
        `,
      `
          با این تیم من روش‌های مقابله با بیماریم رو یاد گرفتم، مثلا یاد گرفتم
          به موسیقی آرام بخش گوش بدم یا احساسات وحشتناکم رو یادداشت کنم.
        `,
      `
          اگرچه به خاطر برداشت اشتباه جامعه در مورد بیماریم، ترجیح دادم رازم رو
          در محدوده خانواده‌ام حفظ کنم.
        `,
    ],
  },
  {
    images: ["story-7"],
    lines: [
      `
          اختلال دوقطبی خیلی پیچیده‌ ست. خیلی از مردم اصلا با این بیماری آشنا
          نیستن. به عنوان یه نوجوان مبتلا به دوقطبی زندگی من متفاوت از سایر
          آدم‌هاست.
        `,
      `
          من هیجان‌ها رو خیلی قوی‌تر از بیشتر آدم ها احساس می‌کنم. گاهی فکرهام
          با هم مسابقه میذارن. گاهی نمی‌تونم سرعت فکرهام رو کنترل کنم و فقط به
          یه موضوع فکر کنم.
        `,
      `
          من استعداد خوبی در خوندن، نوشتن و رقص دارم و از خلاقیتم برای جهت دادن
          به احساساتم استفاده می کنم.
        `,
    ],
  },
  {
    images: ["story-8"],
    lines: [
      `
    گاهی دوست شدن برام خیلی کار سختیه چون خیلی با بقیه فرق دارم. بقیه فکر
    می‌کنن مغرورم که ارتباط برقرار نمی کنم. من اونقدر در مورد خودم و
    برداشت بقیه نگران می‌شم که وقت برای دوستی کم میارم.
  `,
      `
    واقعا دلم نمی‌خواد اینطور رفتار کنم،‌ ولی فکر می‌کنم اینم یکی از معایب
    بیماریم باشه. من نه تنها با احساساتم مشکل دارم بلکه در تکالیف مدرسه هم
    مشکل دارم.
  `,
      `
    موندن تو مدرسه خیلی سخته به خصوص که دوستی ندارم و نیاز دارم که با یه
    نفر در ارتباط باشم.
  `,
    ],
  },
  {
    images: ["story-9"],
    lines: [
      `
          تمرکز کردن برام خیلی کار سختیه چون احساسات آزاردهنده‌ای دارم که حواسم
          رو پرت می کنن. مثلا خیلی روزها غمگینی شدیدی سراغم میاد و کلاس رو با
          گریه ترک می‌کنم.
        `,
      `
          این‌جور وقت‌ها نمی‌تونم به بچه های دیگه نگاه کنم چون می‌ترسم که
          مسخره‌ام کنن. گاهی آرزو می‌کنم کاش یه چاله رو زمین باز بشه و منو پنهان
          کنه.
        `,
      `
          با این وجود خوشبختانه من اراده ای دارم که بهم کمک می کنه ادامه بدم و
          مقاومتی که هر روز قوی‌ترم می‌کنه.
        `,
    ],
  },
  {
    images: ["story-10"],
    lines: [
      `
          اغلب نوجوان ها زندگی عادیشون رو دارن و از اون لذت می برن. در حالی که
          من با داروها،‌ دل درد، اضطراب، تشنگی بی وقفه، آزمایش خون، ساعت خواب و
          ملاقات با دکتر سر و کار دارم.
        `,
      `
          برعکس یه بیماری مثل سرطان، دوقطبی شناخته شده و مورد حمایت نیست. سرطان
          یه بیماری دردناکه ولی در کنارت آدم‌هایی که دوستت دارن و حمایتت می کنن
          هستن تا بیماریت رو شکست بدی. در حالی که در مورد دوقطبی برداشت های
          اشتباه وجود داره و پذیرفته شده نیست.
        `,
      `
          مردم درک نمی‌کنن چقدر سخته به سبکی زندگی کنی که دوستش نداری و خیلی ها
          تمایلی هم ندارن یاد بگیرن. غم انگیزه که با فرد مبتلا به دوقطبی مثل
          دشمن رفتار میشه و نه بیمار.
        `,
    ],
  },
  {
    images: ["story-11"],
    lines: [
      `
          بعد از همه آنچه تجربه کردم و هنوز دارم تجربه می‌کنم، بالاخره نظرم رو
          در مورد دوقطبی تغییر دادم.
        `,
      `
          اولش تمایل شدیدی داشتم که همه چیز رو ول کنم اما بعد سه سال، خوشحالم که
          تشخیص گرفتم. با وجود سختی های خیلی زیادش، فهمیدم که اختلال دوقطبی من
          رو تبدیل به آدم قوی تری کرده.
        `,
      `
          الان میدونم که توانایی تحمل چالش های زندگی رو دارم و امیدوارم یه روز
          بتونم با تجربیاتم به بقیه کمک کنم. من خوشحالم که بیماریم باعث نشده
          رویاهام رو رها کنم. من هیچ وقت اجازه نمیدم دوقطبی شکستم بده.
        `,
    ],
  },
]

const Story = ({ images }) => (
  <Container fluid>
    {sections.map((section, index) => (
      <Row
        className={classNames(
          "justify-content-center",
          "align-items-center",
          "story-section",
          {
            "flex-row-reverse": index % 2 === 0,
          }
        )}
        style={{ backgroundColor: index % 2 === 0 ? "#e7ffef" : "#ffffff" }}
      >
        <Col md={{ span: 4, offset: 1 }}>
          {section.images.map(image => (
            <GatsbyImage
              image={find(images.edges, image).node.childImageSharp.gatsbyImageData}
              className="content-image" />
          ))}
        </Col>
        <Col md={{ span: 4, offset: 1 }}>
          {section.lines.map(line => (
            <p className="font-weight-light text-secondary">{line}</p>
          ))}
        </Col>
      </Row>
    ))}
  </Container>
)

Story.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
}
export default Story
